import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Dots } from '@saleshandy/icons';

import * as Icons from '../../../../shared/svg';
import { UNREAD_MAX_LIMIT, IProps, SidebarNavItem } from './types';

import { navItems, navItemsBottom } from './nav-items';
import NavItem from './nav-item';
import DropDownNavItem from './dropdown-nav-item';
import HelpscoutButton from '../../../../shared/components/helpscout-button';
import LogoSecondary from '../../../../shared/components/logo-secondary';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import history from '../../../../shared/history';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { getHasVisitedAgencyPortal } from '../../../agency-portal/helper';
import { isMultichannelActive } from '../../../../shared/utils/user-details';
import { getIsRequestPending } from '../../../../shared/utils';
import {
  getHasVisitedEmailAccounts,
  setHasVisitedEmailAccounts,
} from '../../../email-accounts/helpers/get-set-has-visited';

const SideNavbar: React.FC<IProps> = ({
  agencyConfig,
  planCode,
  unreadEmailThreadsCount,
  hasVisitedAgencyPortal,
  handleHasVisitedAgencyPortal,
  sendGetUnreadEmailThreadsCountRequest,
  isAgency,
  agencyConfigRequestStatus,
  isEmailAccountAdded,
}) => {
  const route = history.location.pathname;
  const isMultichannelActiveValue = isMultichannelActive();

  const [
    hasVisitedEmailAccountsInLS,
    setHasVisitedEmailAccountsInLS,
  ] = useState(getHasVisitedEmailAccounts() === 'true');

  const [bottomMoreMenu, setBottomMoreMenu] = useState<SidebarNavItem[]>([
    ...navItemsBottom,
  ]);
  const [topNavItems, setTotalNavItems] = useState<SidebarNavItem[]>([
    ...navItems(isMultichannelActiveValue),
  ]);

  const [bottomMoreMenuButton, setBottomMoreMenuButton] = useState(false);
  const sideNavClassName = classNames([
    'bs-sidenav-container',
    'sidenav',
    'sidenav--collapse',
  ]);

  const renderLogo = () => {
    if (
      getIsRequestPending(agencyConfigRequestStatus) ||
      (agencyConfig && agencyConfig?.isLocallyCached)
    ) {
      return null;
    }

    if (
      !agencyConfig?.icon &&
      window.location.origin === process.env.REACT_APP_BASE_URL
    ) {
      return <Icons.SHLogoIcon />;
    }
    return <LogoSecondary />;
  };

  useEffect(() => {}, [agencyConfigRequestStatus]);

  useEffect(() => {
    if (route === '/email-accounts') {
      setHasVisitedEmailAccounts('true');
      setHasVisitedEmailAccountsInLS(true);
      localStorage.setItem('isEmailAccountAdded', 'true');
    } else if (isEmailAccountAdded) {
      setHasVisitedEmailAccounts('false');
      setHasVisitedEmailAccountsInLS(false);
      localStorage.setItem('isEmailAccountAdded', 'false');
    }
  }, [route]);

  useEffect(() => {
    const emailAccountStatus =
      localStorage.getItem('isEmailAccountAdded') === 'true';

    if (emailAccountStatus) {
      setHasVisitedEmailAccountsInLS(true);
    } else {
      setHasVisitedEmailAccountsInLS(false);
    }
  }, []);

  useEffect(() => {}, [agencyConfigRequestStatus]);

  useEffect(() => {
    if (
      planCode &&
      hasPermission(Permissions.UNIFIED_INBOX_READ) &&
      unreadEmailThreadsCount < UNREAD_MAX_LIMIT
    ) {
      sendGetUnreadEmailThreadsCountRequest({
        shouldCalculateFilterCount: false,
      });
    }

    const hasVisitedAgencyPortalInLS = getHasVisitedAgencyPortal() === 'true';

    handleHasVisitedAgencyPortal(hasVisitedAgencyPortalInLS);
  }, [route]);

  const handlerFunctionForSidenav = (height: number) => {
    const { length } = navItems(isMultichannelActiveValue);

    switch (true) {
      case height < 387:
        setBottomMoreMenuButton(true);
        setBottomMoreMenu([
          ...navItems(isMultichannelActiveValue).slice(length - 4, length),
          ...bottomMoreMenu,
        ]);
        setTotalNavItems(
          navItems(isMultichannelActiveValue).slice(0, length - 4),
        );
        break;

      case height < 433:
        setBottomMoreMenu([
          ...navItems(isMultichannelActiveValue).slice(length - 3, length),
          ...bottomMoreMenu,
        ]);
        setTotalNavItems(
          navItems(isMultichannelActiveValue).slice(0, length - 3),
        );
        break;

      case height < 484:
        setBottomMoreMenu([
          ...navItems(isMultichannelActiveValue).slice(length - 2, length),
          ...bottomMoreMenu,
        ]);
        setTotalNavItems(
          navItems(isMultichannelActiveValue).slice(0, length - 2),
        );
        break;

      case height < 530:
        setBottomMoreMenu([
          ...navItems(isMultichannelActiveValue).slice(length - 1, length),
          ...bottomMoreMenu,
        ]);
        setTotalNavItems(
          navItems(isMultichannelActiveValue).slice(0, length - 1),
        );
        break;

      case height < 576:
        setBottomMoreMenuButton(true);
        setBottomMoreMenu([...bottomMoreMenu]);
        setTotalNavItems(navItems(isMultichannelActiveValue));
        break;

      case height > 624:
        setTotalNavItems(navItems(isMultichannelActiveValue));
        setBottomMoreMenuButton(false);
        break;

      default:
        break;
    }
  };
  const handleSidenavScalling = (e: any) => {
    if (e?.currentTarget?.innerHeight < 624) {
      setBottomMoreMenuButton(true);
    }

    handlerFunctionForSidenav(e?.currentTarget?.innerHeight);
  };

  const isTopNavRendered = (navItem) => {
    if (navItem.eventKey === 'agency-client' && !isAgency) {
      return false;
    }
    if (
      navItem.eventKey === 'tasks' &&
      (!isMultichannelActiveValue || !hasPermission(navItem.permission))
    ) {
      return false;
    }

    return navItem.permission && hasPermission(navItem.permission);
  };

  const isBottomNavRendered = (navItem) => {
    if (navItem.eventKey === 'agency-client' && isAgency) {
      return false;
    }

    return navItem.permission && hasPermission(navItem.permission);
  };

  useEffect(() => {
    if (window.innerHeight <= 644) {
      setBottomMoreMenuButton(true);
    }

    handlerFunctionForSidenav(window.innerHeight);

    window.addEventListener('resize', (e) => handleSidenavScalling(e));

    return window.removeEventListener('resize', (e) =>
      handleSidenavScalling(e),
    );
  }, []);

  const isAnyBottomNavMenuVisible = useMemo(
    () => navItemsBottom.some((navItem) => isBottomNavRendered(navItem)),
    [navItemsBottom],
  );

  return (
    <div className={sideNavClassName}>
      <div className="sidenav__header">
        <Link to="/sequence">{renderLogo()}</Link>
      </div>

      <ul className="sidenav__items">
        {topNavItems.map(
          (navItem) =>
            isTopNavRendered(navItem) && (
              <NavItem
                key={navItem.text}
                eventKey={navItem.eventKey}
                route={navItem.route}
                text={navItem.text}
                tooltipText={navItem.tooltipText}
                count={unreadEmailThreadsCount}
                hasVisitedAgencyPortal={hasVisitedAgencyPortal}
                hasVisitedEmailAccounts={hasVisitedEmailAccountsInLS}
              />
            ),
        )}
      </ul>

      <ul className="sidenav__items sidenav__bottom">
        {(isAnyBottomNavMenuVisible ||
          hasPermission(Permissions.CHAT_SUPPORT_SHOW)) && (
          <hr className="sidenav__bottom__divider" />
        )}
        {navItemsBottom.map(
          (navItem) =>
            !bottomMoreMenuButton &&
            isBottomNavRendered(navItem) && (
              <NavItem
                key={navItem.text}
                eventKey={navItem.eventKey}
                route={navItem.route}
                text={navItem.text}
                tooltipText={navItem.tooltipText}
                count={unreadEmailThreadsCount}
                hasVisitedAgencyPortal={hasVisitedAgencyPortal}
                hasVisitedEmailAccounts={hasVisitedEmailAccountsInLS}
              />
            ),
        )}

        {bottomMoreMenuButton && (
          <div
            className="sidenav__item-wrapper nav-item-container position-relative sidenav__extra-menus"
            key="Menu"
          >
            <li
              id="Menu"
              key="Menu"
              className="sidenav__item d-flex align-items-end"
            >
              <div className="sidenav__icon">
                <Dots height={24} width={24} />
              </div>

              <div className="more-menu-wrapper position-absolute">
                <div className="more-menu-dropdown top-0">
                  {bottomMoreMenu.map(
                    (navItem) =>
                      isBottomNavRendered(navItem) && (
                        <DropDownNavItem
                          key={navItem.text}
                          eventKey={navItem.eventKey}
                          route={navItem.route}
                          text={navItem.text}
                        />
                      ),
                  )}
                </div>
              </div>
            </li>
          </div>
        )}
        {hasPermission(Permissions.CHAT_SUPPORT_SHOW) && <HelpscoutButton />}
      </ul>
    </div>
  );
};

export default SideNavbar;
